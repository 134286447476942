import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { StoreListProps } from './models';

import './StoreList.scss';

const StoreList: FC<{ data: StoreListProps }> = ({
  data: {
    storeList: { title, description, logo, link, imageDesktop, imageMobile, items, urls, seo },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const location = useLocation();
  const eventData = { eventType: 'page_view', tagData: { pageTemplate: location.pathname } };

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
        tagEventData={eventData}
      />
      <div className="mx-auto section-container store">
        <div className="row store__banner">
          <div className="col">
            <Image imageData={imageDesktop} alt={title} className="d-none d-md-block" />
            <Image imageData={imageMobile} alt={title} className="d-block d-md-none" />
            <div className="store__banner-text text-center">
              <h2>{title}</h2>
              <p>{description}</p>
              <Image imageData={logo} alt={logo.altText} />
              <div>
                <a className="btn btn-primary" href={link.url} target="_blank" rel="noreferrer">
                  {link.name}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="store__list pt-3">
              <div className="container-fluid store__list-wrapper py-4">
                <div className="row">
                  {items.map(({ link: website, image }) => (
                    <div className="col-6 col-md-4" key={website.url}>
                      <div className="mb-4 p-2 text-center store__list-item">
                        <a
                          href={website.url}
                          target="_blank"
                          rel="noreferrer"
                          className="pb-4 d-block"
                        >
                          {image ? <Image imageData={image} alt={image.altText} /> : null}
                        </a>
                        <a
                          className="btn btn-primary"
                          href={website.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {website.name}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    storeList {
      title
      description
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 240)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 240)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 240)
          }
        }
      }
      link {
        url
        queryString
        name
      }
      imageMobile {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
      }
      imageDesktop {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
      }
      imageMobile {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 2000)
          }
        }
      }
      items {
        link {
          url
          queryString
          name
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default StoreList;
